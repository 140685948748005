export default {
  heading: 'Playfair Display, serif',
  subheading: 'Pridi, sans-serif',
  body: 'Roboto Slab, serif',
  monospace: 'Menlo, monospace',
  display: 'Italiana, cursive',
  display2: 'Charm',
  display3: 'Charm',
  googleFonts: [
    'Charm:100,200,300,400,500,600,700,800,900',
    'Roboto Slab:100,200,300,400,500,600,700,800,900',
    'Playfair Display:100,200,300,400,500,600,700,800,900',
    'Questrial',
    'Pridi',
    'Italiana:100,200,300,400,500,600,700,800,900'
  ]
  // customFamilies: [''],
  // customUrls: [''],
}
