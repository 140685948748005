export default {
  text: '#0c0c0c',
  primary: '#f1eadf',
  secondary: '#a12e37',
  tertiary: '#5B564D',
  background: '#f3f3f3',
  backgroundSecondary: '#393939',
  light: '#FFF',
  dark: '#050707'
}
