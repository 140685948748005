export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  sideBySide2: {
    overflow: 'hidden',
    flexDirection: ['', '', 'row'],
    '.imageContainer': {
      order: '2',
      width: ['', '', '60%'],
      height: ['', '', '95vh'],
      position: 'relative',
      '::before': {
        content: ['none', 'none', "''"],
        border: 'solid 3px',
        borderColor: 'secondary',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%'
      },
      img: {
        // height: '100vh',
        border: 'solid 3px',
        borderColor: 'secondary'
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%']
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%']
      }
    },

    '.content': {
      order: '2',
      width: ['', '', '40%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // Container
    '.container': {
      backgroundColor: 'background',
      padding: ['', '', '', '0rem 0rem'],
      display: 'flex',
      justifyContent: 'center'
    },

    '.containerScrolled': {
      backgroundColor: 'background',
      color: 'text',
      padding: '0rem',
      boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 15%)',
      borderBottom: 'none'
    },

    '.smallNavMenu': {
      // backgroundColor: '#ffffffba',s
      padding: '0.5rem',

      a: {
        fontSize: ['', '', '', '0.8rem', '1rem'],
        color: 'text',
        fontWeight: '400',
        textTransform: 'uppercase'
      }
    },
    '.online-order, .reservations': {
      a: {
        padding: '0.5rem 1rem',
        border: 'solid 1px',
        fontWeight: 'bold'
      }
    },

    // hamburger
    '.hamburger': {
      borderRadius: '10px',
      background: 'none',
      border: 'none',
      padding: '0.75rem',
      '> div': {
        backgroundColor: 'secondary',
        height: '2px'
      },
      'div:nth-of-type(2)': {
        width: '70%'
      }
    },

    '.hamburgerOpen': {
      color: 'secondary',
      borderColor: 'secondary',
      '> div': {
        backgroundColor: 'currentColor'
      }
    },
    'li.navItem': {
      a: {
        color: 'text',
        fontFamily: 'body',
        fontWeight: 'normal',
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
        ':hover': {
          color: 'secondary',
          textDecoration: 'underline'
        }
      }
    },

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      img: {
        filter: 'unset',
        maxHeight: ['75px', '85px']
      }
    },
    '.logoScrolled': {
      img: {
        // filter: 'brightness(0) invert(1)',
        maxHeight: '60px'
      }
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '3rem 0rem',
      pb: '3rem',
      mb: '2rem',
      borderBottom: 'solid 2px',
      borderColor: 'text',
      img: {
        maxHeight: ['70px', '85px'],
        filter: 'unset'
      }
    },

    '.navMenuOpen': {
      color: 'background',

      alignItems: 'flex-start',
      padding: '10vh 10vw',
      'li.navItem': {
        mb: '1rem'
      },
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'none'
      }
    },
    '.seperatorLine': {
      margin: '2rem 0rem'
    }
  },

  footer: {
    backgroundColor: 'black',
    position: ['', '', 'fixed'],
    bottom: '0rem',
    zIndex: '977',
    width: '100%',
    '.logo, .image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.contactDetails-container': {
      display: 'none'
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 3.5rem', '', '0rem 3rem 0rem'],
    '.multiButtonContainer': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0rem',
      padding: '0.5rem 0.5rem 0.5rem 0rem',
      a: {
        color: 'white',
        fontSize: '0.9rem',
        margin: '0rem',
        padding: '0.5rem 0.5rem 0.5rem 0rem'
      }
    },
    '.gonationLogo': {
      width: 'fit-content',
      padding: '0.25rem 1rem 0.25rem 0rem'
    },

    '.socialIconsContainer': {
      padding: '0.25rem 1rem 0.25rem 0rem',
      a: {
        margin: '0.25rem'
      }
    },
    '.copyright': {
      color: 'white',
      padding: '0.25rem 1rem 0.25rem 0rem',
      margin: '0rem',
      textAlign: 'center',
      fontSize: '0.8rem'
    }
  },

  ctaWidget: {
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.5rem', '2.75rem', '3.5rem', '4rem', '4.5rem'],
    fontWeight: 'normal',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'primary',
    fontStyle: 'italic'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'left',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',
    color: 'secondary',
    border: 'none',
    fontWeight: 'bold',
    fontFamily: 'subheading',
    color: 'secondary',
    textTransform: 'uppercase',

    letterSpacing: '1.5px'
  },
  text: {
    // textAlign: 'center',
    lineHeight: '1.75',
    '*': {
      lineHeight: '1.75',
      textAlign: 'inherit',
      fontSize: 'inherit',
      color: 'inherit'
    }
  },

  sideBySide1: {
    height: ['', '', '80vh'],
    alignItems: ['center', 'center', 'center', 'center'],
    overflow: 'hidden',
    margin: ['', '', '10vh 1rem'],
    '.content': {
      width: ['100%', '', '40%'],
      backgroundColor: 'white',
      zIndex: '1'
    },
    '.lazyload-wrapper': {
      width: ['100%', '', '60%'],
      maxHeight: '90vh',
      height: '100%',
      position: 'relative',
      left: ['', '', '-4rem', '-10rem'],
      zIndex: '0',
      overflow: 'visible',
      img: {
        width: '120%',
        maxWidth: '120%'
      }
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      padding: '0rem',
      textAlign: 'left',
      position: 'relative',
      left: '-0.5rem',
      marginBottom: '0.5rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'left'
    },
    '.text': {
      variant: 'customVariants.text',
      mt: '1rem'
    },
    a: {
      order: '4'
    },
    '.linksContainer': {
      mt: '2rem',
      justifyContent: 'flex-start',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  centerBlock: {
    variant: 'customVariants.sideBySide1',
    margin: '0rem',
    '*': {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0rem auto'
    },
    '.title': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      color: 'white',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
      textShadow: '2px 2px 10px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      color: 'background'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      color: 'white',
      '*': {
        color: 'white'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    padding: ['3rem 0rem 0rem', '', '105px 0rem 0rem'],
    height: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '.slick-slider, .slick-list, .slick-slide, .slick-slide > div, .slick-slide img': {
      height: 'calc(100vh - 165px) !important',
      width: '100%'
    },
    'svg.slick-arrow': {
      backgroundColor: '#cea3526e',
      height: '30px',
      width: '30px',
      color: 'white',
      padding: '0.1rem'
    },
    'svg.slick-prev': {
      left: '0.25rem'
    },
    'svg.slick-next': {
      right: '0.25rem'
    },

    '.hero_content_container': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center'
    },

    '.title': {
      variant: 'customVariants.title',
      fontWeight: 'bold',
      fontSize: ['3rem', '4rem', '5rem', '6rem', '7rem', '8rem'],
      fontFamily: 'display3',
      marginBottom: '0rem',
      color: 'text',
      borderBottom: '1px solid'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.75rem', '2rem', '2.5rem', '3rem'],
      fontFamily: 'body',
      color: 'text',
      fontWeight: '200',
      marginBottom: '0rem',
      textAlign: 'right',
      width: '90%',
      textTransform: 'lowercase'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none !important'
  },

  homepageShout: {
    backgroundColor: 'background',

    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      backgroundColor: 'secondary',
      fontFamily: 'body'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2rem'],
      order: ['4', '', '3'],

      fontWeight: 'bold',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['secondary', '', '', 'secondary'],
      color: ['white', '', '', 'white'],
      margin: ['', '', '', '8px'],
      fontFamily: 'display'
    },
    '.date': {
      backgroundColor: 'black',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      fontSize: '0.8rem',
      color: 'white'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },
    '.shoutCTABtns': {
      order: '4',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'background'],
      a: {
        variant: 'buttons.primary'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageAbout2: {
    variant: 'customVariants.sideBySide1',
    'div.lazyload-wrapper': {
      left: '0rem'
    }
  },

  homepageBoxes: {
    backgroundColor: 'backgroundSecondary',
    margin: '0rem',
    '.box': {
      position: 'relative',
      minHeight: '40vh',
      transition: 'all ease-in-out 0.8s',
      color: 'white',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '.image': {
        transition: 'all ease-in-out 0.8s',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        opacity: ['0.3', '', '', '1']
      },
      ':hover': {
        '.image': {
          opacity: '0'
        }
      },

      '.title': {
        variant: 'customVariants.title',
        textAlign: 'center',
        color: 'white',
        margin: '0rem',
        fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem', '2.75rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        textAlign: 'center',
        color: 'secondary',
        fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.4rem']
      },
      '.text': {
        variant: 'customVariants.text',
        textAlign: 'center',
        color: 'white',
        '*': {
          color: 'white'
        }
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      '.lazyload-wrapper': {
        width: '100%!important'
      }
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },
  homepageTeam: {
    variant: 'customVariants.sideBySide1'
  },
  homepageContact: {
    variant: 'customVariants.centerBlock'
  },

  homepageQuote1: {
    variant: 'customVariants.centerBlock'
  },
  homepageQuote2: {
    variant: 'customVariants.centerBlock',
    margin: '0rem'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  privateEvents1: {
    variant: 'customVariants.sideBySide2',
    '.content': {
      '.title': {
        variant: 'customVariants.title',
        color: 'dark',
        textAlign: 'center'
      },
      '.text': {
        variant: 'customVariants.text',
        textAlign: 'center'
      }
    }
  },
  privateEvents2: {
    variant: 'customVariants.sideBySide2',
    '.content': {
      '.title': {
        variant: 'customVariants.title',
        color: 'dark',
        textAlign: 'center'
      },
      '.text': {
        variant: 'customVariants.text',
        textAlign: 'center'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    position: 'relative',
    '::before': {
      content: '""',
      background: 'rgba(0,0,0,0.5)',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: '0'
    },
    '*': {
      zIndex: '2'
    },
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem', '3.5rem']
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: '#562316',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.primary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '1rem',
      width: ['100%', '', '45%', '40%'],
      backgroundColor: '#f1eadf'
    },
    iframe: {
      width: ['100%', '', '55%', '60%']
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      color: 'text',
      width: '100%',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center',
      borderTop: '1px solid black',
      borderBottom: '1px solid black'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Team page  ====
  // ? ========================

  teamMenu: {
    padding: ['0rem', '0rem', '0rem', '0rem'],
    '.box': {
      height: '100%'
    },
    '.textContent': {
      position: 'static',
      transform: 'unset',
      '.title': {
        color: 'text'
      }
    }
  },

  teamPage: {
    backgroundColor: 'white',

    '::before': {
      backgroundColor: 'white',
      content: '""',
      height: '140px'
    },
    '.title': {
      color: 'text'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'cover'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
